import * as React from "react";
import Helmet from "react-helmet";

import { Link } from "gatsby";

import styled from "styled-components";
import { createGlobalStyle } from "styled-components";

import { respondTo } from "../_respondTo";

import { StaticImage } from "gatsby-plugin-image";
import logo from "../images/twg_logo.svg";

import { PageWrapper, TextWrapper } from "../components/layout";
import Navigation from "../components/navigation";
import Footer from "../components/footer";
import NextButton from "../components/next-button";

const GlobalStyle = createGlobalStyle`
  body{
    margin:0;
    font-family: sans-serif;
  }
`;

const LogoWrapper = styled.div`
  margin: auto;
  width: 25%;
  max-width: 175px;
  text-align: center;
`;

const ContentWrapper = styled.div`
  margin: auto;
  text-align: center;
  color: #020f73;
  font-family: sans-serif;
`;

// markup
const AboutUsPage = () => {
  return (
    <>
      <Helmet>
        {" "}
        <title>About Us | True Wealth Group</title>
        <meta name="description" content="About True Wealth Group" />
        <script defer src="https://unpkg.com/@tinybirdco/flock.js" data-host="https://api.tinybird.co" data-token="p.eyJ1IjogImI3YzA5NWQxLThiMTctNGQ5Ni04ODUwLWUxNjM0YmU5NzgyOCIsICJpZCI6ICI4Njg5NGY0MC00ZjBlLTRiMGMtYTI2YS0xMmNkZTBlYjEyOWMifQ.cXvEL-7r0R4anvPPduWPoDsUKPPZeyI54Z2BuA_L5E8"></script>
      </Helmet>
      <PageWrapper>
        <Navigation></Navigation>
        <GlobalStyle />
        <StaticImage
          alt="Background image of person walking up path on a mountain"
          src={"../images/bg-img.jpg"}
          formats={["auto", "webp", "avif"]}
          style={{
            position: "fixed",
            top: "0",
            width: "100vw",
            height: "100vh",
            zIndex: "-1",
          }}
          loading="eager"
        />
        <LogoWrapper>
          <img
            src={logo}
            style={{ width: "100%", paddingTop: "25%", aspectRatio: "1/1" }}
            alt="True Wealth Group logo"
          />
        </LogoWrapper>{" "}
        <ContentWrapper>
          <h1>About Us</h1>
        </ContentWrapper>
        <TextWrapper>
          <p>
            The True Wealth Group’s management team has been providing financial
            planning, wealth management, consultancy and other services together
            for 20 years. Most recently it has assembled a tribe of like-minded
            people to capitalise on the incredible opportunities developed over
            the last 2 decades:
          </p>{" "}
          <ul>
            <li>
              delivering a first class financial planning wealth management
              service to affluent and high net worth clients
            </li>
            <li>
              acquiring and integrating some of the best boutique IFA practices
              in the UK{" "}
            </li>
            <li>
              establishing value accretive and lead generative joint ventures
              with other businesses/professions
            </li>
            <li>
              deploying its ‘turnkey’, ‘enhance &amp; uplift’ solutions to
              realise ‘true value’ with the parties it works with{" "}
            </li>
          </ul>
          <p>
            Independently owned and run, we grow organically and through the
            acquisition of financial planning and wealth management firms who
            share our philosophy and our core values. With its head office in
            Leeds, the True Wealth Group has 5 offices across the UK (including
            Northern Ireland) serving the needs of clients UK wide.
          </p>{" "}
          <p>
            Explore this site to find out more about us as a Group, what we
            stand for and what we mean by ‘True Wealth’. Our proposition is the
            same across the group, so if you are a wealthy individual or
            business owner who would like us to work with you on achieving your
            True Wealth visit one of the brands on our{" "}
            <Link to="/#ourfirms">homepage</Link> or{" "}
            <Link to="/contact">contact us</Link>.
          </p>
        </TextWrapper>
        <NextButton url="/finding-true-wealth" name="Finding True Wealth" />
      </PageWrapper>
      <Footer></Footer>
    </>
  );
};

export default AboutUsPage;

export const Head = () => (
  <>
    <title>About Us | True Wealth Group</title>
    <meta name="description" content="About True Wealth Group" />
    <meta name="keywords" content="True Wealth Group" />
  </>
);
